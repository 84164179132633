import {
   Grid,
   Typography,
   List,
   ListItem,
   IconButton,
   Divider,
   Checkbox,
   Link,
   FormControlLabel,
   FormControl,
   Radio,
   RadioGroup,
   Collapse,
} from "@mui/material";
import {
   RoomOutlined,
   EventOutlined,
   EventAvailableOutlined,
   NoteAltOutlined,
   CloseOutlined,
   CheckCircleOutline,
   CreditCardOutlined
} from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment/moment";
import { Box, Container } from "@mui/system";
import CustomButton from "../../shared/Button";
import TextFeildCustom from "../../shared/TextFeild";
import CustomDailog from "../../shared/Dailog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { specificStep } from "../../../store/steps/storeSteps";
import { checkPromo } from "../../../cleancloud/checkPromo";
import {
   changeCheckList,
   changeComments,
   changePromoDiscount,
   changeSubTotal,
   changeTermsAndConditions,
} from "../../../store/order/storeOrder";
import { ScrollRestoration } from "react-router-dom";

export default function Review() {
   const { address } = useSelector((store) => store.address);
   const { subscription } = useSelector((store) => store.subscription);
   const { products } = useSelector((store) => store.products);
   const { date, deliveryRange } = useSelector((store) => store.date);
   const { promoDiscount, comments } = useSelector((store) => store.order);
   const { cid } = useSelector((store) => store.createAccount);
   const { updateOrderID } = useSelector((store) => store.updateOrder);
   const [open, setOpen] = useState(false);
   const [openTerms, setOpenTerms] = useState(false);
   const [promo, setPromo] = useState("");
   const [promoValid, setPromoValid] = useState({
      message: " ",
      error: false,
      loading: false,
   });
   const [agreeTemp, setAgreeTemp] = useState(true);
   const [agreeDelivery, setAgreeDelivery] = useState(true);
   const [agreeRequirement, setAgreeRequirement] = useState(false);
   const [hasNotes, setHasNotes] = useState(false);

   const dispatch = useDispatch();

   const handleClickClose = () => {
      setOpen(false);
   };
   const handleClickOpen = () => {
      setOpen(true);
   };

   const applyPromo = async () => {
      setPromoValid({
         message: " ",
         error: false,
         loading: true,
      });
      try {
         const response = await checkPromo(
            promo,
            localStorage.getItem("cid")
               ? JSON.parse(localStorage.getItem("cid"))
               : cid
         );
         if (response.Error) {
            setPromoValid({
               message: response.Error,
               error: true,
               loading: false,
            });
            return;
         }

         dispatch(changePromoDiscount(parseFloat(response.creditAmount)));
         setPromoValid({
            message: "",
            error: false,
            loading: false,
         });
      } catch (e) {
         setPromoValid({
            message: "Something Went Wrong!",
            error: true,
            loading: false,
         });
      }
   };

   const calculateTotal = () => {
      const productsTotal = products.reduce((partial, item) => {
         return (
            parseFloat(parseFloat(partial).toFixed(2)) +
            parseFloat(item.price) * item.quantity
         );
      }, 0);
      const nextDayDeliveryCharges = deliveryRange === "24hrs" ? 4.0 : 0.0;
      const deliveryCharges = subscription ? 0.0 : 10.0;
      const subTotalValue = parseFloat(
         (
            productsTotal +
            nextDayDeliveryCharges +
            deliveryCharges -
            promoDiscount
         ).toFixed(2)
      );

      dispatch(changeSubTotal(subTotalValue));
   };
   useEffect(() => {
      calculateTotal();
      //eslint-disable-next-line
   }, []);
   useEffect(() => {
      calculateTotal();
      //eslint-disable-next-line
   }, [promoDiscount]);
   useEffect(() => {
      if (!agreeDelivery || !agreeTemp || agreeRequirement) setHasNotes(true);
      else {
         setHasNotes(false);
         dispatch(changeComments(""));
      }
      //eslint-disable-next-line
   }, [agreeDelivery, agreeRequirement, agreeTemp]);

   return (
      <Container
         maxWidth={"md"}
         className="d-flex flex-column align-items-center"
         sx={{ paddingRight: 0, paddingLeft: 0 }}
      >
         <ScrollRestoration />
         <Typography variant="h1" align="center" className="pb-4">
            Order review
         </Typography>
         {/* <Typography className="text-center">
        Please make sure you have reviewed all details
      </Typography> */}
         <Container maxWidth={"sm"} className="m-0 p-0 my-3">
            <Grid container spacing={3}>
               <Grid item xs={12}>
                  <Box sx={{ borderRadius: 5, overflow: "hidden" }}>
                     <div className="bg-dark  p-3 px-4">
                        <Typography variant="h6" className="text-white">
                           Order information
                        </Typography>
                     </div>
                     <div className="p-2  bg-white">
                        <List>
                           <ListItem
                              className="d-flex align-items-center px-4"
                              key={"item" + 1}
                           >
                              <RoomOutlined fontSize="small" />
                              <Typography className="mx-1">
                                 Pick-up from {address.formatted_address}
                              </Typography>
                           </ListItem>
                           <ListItem
                              className="d-flex align-items-center px-4"
                              key={"item" + 2}
                           >
                              <EventOutlined fontSize="small" />
                              <Typography className="mx-1">
                                 Pick-up on{" "}
                                 {moment(date).format("dddd DD MMM YYYY")}
                              </Typography>
                           </ListItem>
                           <ListItem
                              className="d-flex align-items-center px-4"
                              key={"item" + 3}
                           >
                              <AccessTimeIcon fontSize="small" />
                              <Typography className="mx-1">
                                 Pick-up between 8am and 12pm
                              </Typography>
                           </ListItem>
                           <ListItem
                              className="d-flex align-items-center px-4"
                              key={"item" + 4}
                           >
                              <EventAvailableOutlined fontSize="small" />
                              <Typography className="mx-1">
                                 Delivering in {deliveryRange}*
                              </Typography>
                           </ListItem>
                           <ListItem
                              className="d-flex align-items-center px-4"
                              key={"item" + 5}
                           >
                              <CreditCardOutlined fontSize="small" />
                              <Typography className="mx-1">
                                 Your card will be charged after delivery
                              </Typography>
                           </ListItem>
                           <ListItem
                              className="d-flex align-items-center px-4"
                              key={"item" + 6}
                           >
                              <Typography
                                 variant="body2"
                                 sx={{ fontSize: "13px" }}
                              >
                                 * Delivery applies during business hours on
                                 weekdays only.
                              </Typography>
                           </ListItem>
                        </List>
                     </div>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box sx={{ borderRadius: 5, overflow: "hidden" }}>
                     <div
                        className={`p-3 px-4 d-flex align-items-center justify-content-between ${
                           subscription ? " bg-secondary" : "bg-info"
                        }`}
                     >
                        <div>
                           <Typography
                              variant="overline"
                              className=" text-uppercase"
                           >
                              Subscription
                           </Typography>
                           <Typography variant="h6">
                              {subscription
                                 ? "FOAM Repeat"
                                 : "Clean, Dry and Fold"}
                           </Typography>
                        </div>
                        <CustomButton
                           variant="contained"
                           color="white"
                           sx={{ fontWeight: 500, fontSize: "16px" }}
                           size="small"
                           className="d-none d-md-block"
                           onClick={() => {
                              if (localStorage.getItem("cid")) {
                                 dispatch(specificStep(1));
                              } else {
                                 dispatch(specificStep(2));
                              }
                           }}
                        >
                           Change
                        </CustomButton>
                        <IconButton
                           className="bg-white d-md-none"
                           onClick={() => {
                              if (localStorage.getItem("cid")) {
                                 dispatch(specificStep(1));
                              } else {
                                 dispatch(specificStep(2));
                              }
                           }}
                        >
                           <NoteAltOutlined />
                        </IconButton>
                     </div>
                     <div className="p-3 px-4  bg-white d-flex align-items-center justify-content-between">
                        <Typography>
                           {products.filter(
                              (item) =>
                                 item.section === "7" || item.section === "17"
                           ).length
                              ? products
                                   .filter(
                                      (item) =>
                                         item.section === "7" ||
                                         item.section === "17"
                                   )
                                   .map((ele, i) => (
                                      <span key={"subs-item-" + i}>
                                         {ele.name}
                                      </span>
                                   ))
                              : "No Items Here"}
                        </Typography>
                        <Typography variant="h6">
                           {products.filter(
                              (item) =>
                                 item.section === "7" || item.section === "17"
                           ).length
                              ? "$" +
                                products.filter(
                                   (item) =>
                                      item.section === "7" ||
                                      item.section === "17"
                                )[0].price
                              : ""}
                        </Typography>
                     </div>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box sx={{ borderRadius: 5, overflow: "hidden" }}>
                     <div className="bg-info  p-3 px-4 d-flex align-items-center justify-content-between">
                        <div>
                           <Typography variant="h6">Bulky items</Typography>
                        </div>
                        <CustomButton
                           variant="contained"
                           color="white"
                           sx={{ fontWeight: 500, fontSize: "16px" }}
                           size="small"
                           className="d-none d-md-block"
                           onClick={() => {
                              if (localStorage.getItem("cid")) {
                                 if (updateOrderID) dispatch(specificStep(1));
                                 else dispatch(specificStep(2));
                              } else {
                                 dispatch(specificStep(3));
                              }
                           }}
                        >
                           Change
                        </CustomButton>
                        <IconButton
                           className="bg-white d-md-none"
                           onClick={() => {
                              if (localStorage.getItem("cid")) {
                                 if (updateOrderID) dispatch(specificStep(1));
                                 else dispatch(specificStep(2));
                              } else {
                                 dispatch(specificStep(3));
                              }
                           }}
                        >
                           <NoteAltOutlined />
                        </IconButton>
                     </div>
                     <div className="p-3 px-4  bg-white ">
                        {products.filter((item) => item.section === "9")
                           .length ? (
                           products
                              .filter((item) => item.section === "9")
                              .map((item, i) => (
                                 <ListItem
                                    key={"heavyitem" + i}
                                    className="d-flex align-items-center justify-content-between px-0"
                                    sx={{
                                       borderTop:
                                          i !== 0
                                             ? "1px solid #f7f7f7"
                                             : "none",
                                    }}
                                 >
                                    <Typography>
                                       {item.quantity} X {item.name}
                                    </Typography>
                                    <Typography variant="h6">
                                       $
                                       {(
                                          parseFloat(item.price) * item.quantity
                                       ).toFixed(2)}
                                    </Typography>
                                 </ListItem>
                              ))
                        ) : (
                           <Typography>No bulky items selected</Typography>
                        )}
                     </div>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box sx={{ borderRadius: 5, overflow: "hidden" }}>
                     <div className="bg-info  p-3 px-4 d-flex align-items-center justify-content-between">
                        <div>
                           <Typography variant="h6">Other</Typography>
                        </div>
                        <CustomButton
                           variant="contained"
                           color="white"
                           size="small"
                           sx={{ fontWeight: 500, fontSize: "16px" }}
                           className="d-none d-md-block"
                           onClick={() => {
                              if (localStorage.getItem("cid")) {
                                 if (updateOrderID) dispatch(specificStep(1));
                                 else dispatch(specificStep(2));
                              } else {
                                 dispatch(specificStep(4));
                              }
                           }}
                        >
                           Change
                        </CustomButton>
                        <IconButton
                           className="bg-white d-md-none"
                           onClick={() => {
                              if (localStorage.getItem("cid")) {
                                 if (updateOrderID) dispatch(specificStep(1));
                                 else dispatch(specificStep(2));
                              } else {
                                 dispatch(specificStep(4));
                              }
                           }}
                        >
                           <NoteAltOutlined />
                        </IconButton>
                     </div>
                     <div className="px-4 py-1  bg-white ">
                        <List className="w-100">
                           {products.filter((item) => item.section === "16")
                              .length
                              ? products
                                   .filter((item) => item.section === "16")
                                   .map((item, i) => (
                                      <div key={"other" + i}>
                                         <ListItem
                                            key={"heavyitem" + i}
                                            className="d-flex align-items-center justify-content-between px-0"
                                         >
                                            <Typography>{item.name}</Typography>
                                            <Typography variant="h6">
                                               ${item.price}
                                            </Typography>
                                         </ListItem>
                                         <Divider />
                                      </div>
                                   ))
                              : ""}
                           <div className="d-flex flex-column px-0">
                              <ListItem className="d-flex align-items-center justify-content-between w-100 px-0">
                                 <Typography>Pickup & Delivery fee</Typography>
                                 <Typography variant="h6">
                                    {" "}
                                    {subscription ? "Free" : "$10.00"}
                                 </Typography>
                              </ListItem>
                              {promoDiscount || deliveryRange === "24hrs" ? (
                                    <Divider className="w-100 mt-2" />
                              ) : (
                                 ""
                              )}
                           </div>
                           {deliveryRange === "24hrs" ? (
                              <div className="d-flex flex-column px-0">
                                 <ListItem className="d-flex align-items-center justify-content-between w-100 px-0">
                                    <Typography>Next day delivery</Typography>
                                    <Typography variant="h6">
                                       {subscription ? "$2.00" : "$4.00"}
                                    </Typography>
                                 </ListItem>
                                 {promoDiscount ? (
                                    <Divider className="w-100 mt-2" />
                                 ) : (
                                    ""
                                 )}
                              </div>
                           ) : (
                              ""
                           )}
                           {promoDiscount ? (
                              <ListItem className="d-flex flex-column px-0">
                                 <div className="d-flex align-items-center justify-content-between w-100 ">
                                    <Typography className="text-success text-uppercase">
                                       YOURCOUPON
                                    </Typography>
                                    <Typography
                                       className="text-success"
                                       variant="h6"
                                    >
                                       ${promoDiscount}
                                    </Typography>
                                 </div>
                              </ListItem>
                           ) : (
                              ""
                           )}
                        </List>
                     </div>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box sx={{ borderRadius: 5, overflow: "hidden" }}>
                     <div className="bg-info  p-3 px-4 d-flex align-items-center justify-content-between">
                        <div>
                           <Typography variant="h6">
                              Settings and Delivery
                           </Typography>
                        </div>
                     </div>
                     <div className="px-4 py-1  bg-white ">
                        <List className="w-100">
                           <div className="d-flex flex-column px-0">
                              <ListItem className="d-flex flex-column align-items-start justify-content-between w-100 px-0">
                                 <Typography>
                                    I am happy for my items to be washed in{" "}
                                    <b>
                                       cold water (30 degrees) and dried at
                                       medium temperature (71 degrees)
                                    </b>
                                    . If not, please let us know your
                                    requirements in the comment section below.
                                 </Typography>
                                 <FormControl>
                                    <RadioGroup
                                       row
                                       aria-labelledby="fm-set-temperature"
                                       name="temperature"
                                       defaultValue="yes"
                                       onChange={(e) =>
                                          setAgreeTemp(
                                             e.target.value === "yes"
                                                ? true
                                                : false
                                          )
                                       }
                                    >
                                       <FormControlLabel
                                          value="yes"
                                          control={<Radio />}
                                          label="Yes"
                                       />
                                       <FormControlLabel
                                          value="no"
                                          control={<Radio />}
                                          label="No"
                                       />
                                    </RadioGroup>
                                 </FormControl>
                              </ListItem>
                              <Divider className="w-100 " />
                           </div>
                           <div className="d-flex flex-column px-0">
                              <ListItem className="d-flex flex-column align-items-start justify-content-between w-100 px-0">
                                 <Typography>
                                    <b>Okay to leave?</b> If not, please let us
                                    know if there is a safe place for us to
                                    leave the clean laundry.
                                 </Typography>
                                 <FormControl>
                                    <RadioGroup
                                       row
                                       aria-labelledby="fm-set-delivery"
                                       name="delivery"
                                       defaultValue="yes"
                                       onChange={(e) =>
                                          setAgreeDelivery(
                                             e.target.value === "yes"
                                                ? true
                                                : false
                                          )
                                       }
                                    >
                                       <FormControlLabel
                                          value="yes"
                                          control={<Radio />}
                                          label="Yes"
                                       />
                                       <FormControlLabel
                                          value="no"
                                          control={<Radio />}
                                          label="No"
                                       />
                                    </RadioGroup>
                                 </FormControl>
                              </ListItem>
                              <Divider className="w-100 " />
                           </div>
                           <div className="d-flex flex-column px-0">
                              <ListItem className="d-flex flex-column align-items-start justify-content-between w-100 px-0">
                                 <Typography>
                                    Do you have any additional requirements?
                                 </Typography>
                                 <FormControl>
                                    <RadioGroup
                                       row
                                       aria-labelledby="fm-set-requirement"
                                       name="requirement"
                                       defaultValue="no"
                                       onChange={(e) =>
                                          setAgreeRequirement(
                                             e.target.value === "yes"
                                                ? true
                                                : false
                                          )
                                       }
                                    >
                                       <FormControlLabel
                                          value="yes"
                                          control={<Radio />}
                                          label="Yes"
                                       />
                                       <FormControlLabel
                                          value="no"
                                          control={<Radio />}
                                          label="No"
                                       />
                                    </RadioGroup>
                                 </FormControl>
                              </ListItem>
                              <Divider className="w-100 " />
                           </div>
                           <Collapse
                              in={hasNotes}
                              component="div"
                              className="d-flex flex-column px-0 py-2"
                           >
                              <TextFeildCustom
                                 fullWidth
                                 rows={6}
                                 multiline
                                 label={"Your notes"}
                                 value={comments}
                                 onChange={(e) =>
                                    dispatch(changeComments(e.target.value))
                                 }
                              />
                           </Collapse>
                        </List>
                     </div>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <div style={{ position: "relative" }}>
                     <TextFeildCustom
                        variant="filled"
                        label="Promo Code"
                        fullWidth
                        value={promo}
                        sx={{ textTransform: "uppercase" }}
                        helperText={promoValid.message}
                        error={promoValid.error}
                        onChange={(e) => {
                           setPromoValid({ message: " ", error: false });
                           setPromo(e.target.value.toUpperCase());
                        }}
                     />
                     <CustomButton
                        size="small"
                        variant="outlined"
                        sx={{
                           position: "absolute",
                           right: 10,
                           top: "37%",
                           fontWeight: 500,
                           transform: "translateY(-50%)",
                           padding: "7px 20px",
                        }}
                        onClick={applyPromo}
                        loader={promoValid.loading ? 1 : 0}
                        disabled={promoValid.loading}
                     >
                        Apply
                     </CustomButton>
                  </div>
               </Grid>
               <Grid item xs={12}>
                  <FormControlLabel
                     className="d-flex align-items-center"
                     control={
                        <Checkbox
                           className="m-0 p-0 px-2"
                           onChange={(e) =>
                              dispatch(
                                 changeTermsAndConditions(e.target.checked)
                              )
                           }
                        />
                     }
                     label={
                        <Typography variant="body2">
                           I agree to the full Foam Laundry Service{" "}
                           <a
                              href="https://docs.google.com/document/d/1BnjmsovNOcssEg3dh8vr1aQyvr1gWtd4/edit?usp=sharing&ouid=117092998832246815496&rtpof=true&sd=true"
                              target="_blank"
                              rel="noreferrer"
                              className="text-dark"
                           >
                              Terms & Conditions
                           </a>
                           .
                        </Typography>
                     }
                  ></FormControlLabel>
                  <FormControlLabel
                     className="d-flex align-items-center mt-2"
                     control={
                        <Checkbox
                           className="m-0 p-0 px-2"
                           onChange={(e) =>
                              dispatch(changeCheckList(e.target.checked))
                           }
                        />
                     }
                     label={
                        <Typography variant="body2">
                           I have reviewed my items according to the{" "}
                           <Link
                              to={""}
                              className="text-dark"
                              onClick={handleClickOpen}
                           >
                              FOAM Pick-up Checklist and Guidelines.
                           </Link>
                           .
                        </Typography>
                     }
                  ></FormControlLabel>
               </Grid>
            </Grid>
         </Container>
         <CustomDailog open={open} handleClickClose={handleClickClose}>
            <div className="mt-3 mx-4">
               <IconButton
                  color="secondary"
                  size="small"
                  className="d-flex align-items-center bg-white"
                  onClick={handleClickClose}
                  style={{ position: "absolute", right: 16 }}
               >
                  <CloseOutlined />
               </IconButton>
               <Typography variant="h1" className="py-3">
                  Final Checklist
               </Typography>
               <List className="px-1 w-100 fm-mui-list">
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        Every item in my laundry pile is suitable for washing
                        and drying with Foam Detergent & Softener on our default
                        settings (Wash 30 degrees & Dry 71 Degrees). If not, I
                        have specified instructions and will leave my own
                        detergent with washing for pick up.
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        I will check my pockets & sort my laundry into separate
                        bags if I require them to be washed as separate loads.
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        I understand the bag wash costs calculated are an
                        estimate only. Foam will weigh my dry laundry and
                        calculate final costs prior to delivery.
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        I accept that a 'Ghosting Fee' of $10 will be charged
                        for missed pick ups or deliveries that cannot be made.
                     </Typography>
                  </ListItem>
               </List>
               <Typography variant="h6" className="mt-3">
                  What's in
               </Typography>
               <List className="px-1 w-100 fm-mui-list">
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        Clothes (excluding woolens, sequins and delicates)
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        Bed Sheets, Pillow cases, duvet covers
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Towels</Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        Pillows, Blankets, Doonas, Mattress Protectors can fit
                        in XXL Machines.
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Curtains</Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        Furniture Upholstery
                     </Typography>
                  </ListItem>
               </List>
               <Typography variant="h6" className="mt-3">
                  What's out
               </Typography>
               <List className="px-1 w-100 fm-mui-list">
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        Animal Blankets; only to be washed at our dedicated Pet
                        Laundry Machines at Elizabeth Bay.
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Shoes</Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Suede</Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Leather</Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">
                        Wool / Cashmere (unless it’s washable merino)
                     </Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Suits</Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Silk</Typography>
                  </ListItem>
                  <ListItem>
                     <CheckCircleOutline />
                     <Typography className="mx-1">Wedding Dresses</Typography>
                  </ListItem>
               </List>
            </div>
         </CustomDailog>
         <CustomDailog
            open={openTerms}
            handleClickClose={() => setOpenTerms(false)}
         >
            <div className="mt-3 mx-4 fm-disclaimers">
               <IconButton
                  color="secondary"
                  size="small"
                  className="d-flex align-items-center bg-white"
                  onClick={() => setOpenTerms(false)}
                  style={{ position: "absolute", right: 16 }}
               >
                  <CloseOutlined />
               </IconButton>
               <Typography variant="h1" className="py-3">
                  Terms &amp; Conditions
               </Typography>
               <Typography variant="h6" className="mt-3">
                  Background details
               </Typography>
               <Typography variant="body2">
                  Things to know before you take us for a spin!
               </Typography>
               <Typography variant="body2">
                  This document sets out how we deliver our various Laundry
                  Solutions Services to you (Terms).
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     Foam Laundry Lounge, we, or us means Sapouni Pty Ltd ACN
                     620 889 625 trading as Foam Laundry Lounge ABN 79 302 658
                     369
                  </Typography>
                  <Typography variant="body2" component="li">
                     Customer, you, or your means the cleanliness lover who buys
                     Solutions from us or visits our Laundromats.
                  </Typography>
               </ul>
               <Typography variant="body2">
                  The Terms when you buy our Solutions or visit our Laundromats.
               </Typography>
               <Typography variant="body2">
                  These Terms apply if you buy our ‘done for you’ laundry
                  Solutions.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     These Terms apply to everyone who uses buys our Solutions,
                     so we’ve tried to make them easy to understand and fair.
                  </Typography>
                  <Typography variant="body2" component="li">
                     If you don’t agree to our Terms, that’s okay, but you can’t
                     visit our Laundromats or use our Solutions.
                  </Typography>
               </ul>
               <Typography variant="h6" className="mt-3">
                  Our agreement with you
               </Typography>
               <Typography variant="body2">
                  Our agreement with you is set out in service specific terms
                  'Service' (terms for our range of ‘done for you’ Laundry
                  Solutions, set out on our Website).
               </Typography>
               <Typography variant="h6">1.You prepare</Typography>
               <Typography variant="body2">
                  Here’s a list for you to prep your next load:
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     <b>Washable:</b> Make sure each item is suitable for
                     washing and drying using [water, detergent, wash cycle at
                     30 degrees, and dried at 71 degrees ] (i.e. keep your dry
                     clean only ball gowns for the drycleaner).
                  </Typography>
                  <Typography variant="body2" component="li">
                     <b>Pockets:</b> Check pockets for stray tickets, pens,
                     tissues, treasure maps, keys, dollar dollar bills y’all,
                     etc.
                  </Typography>
                  <Typography variant="body2" component="li">
                     <b>Separate:</b> If you wish to separate your load, please
                     place items into separate bags.
                  </Typography>
               </ul>
               <Typography variant="subtitle1" className="mt-3">
                  Everything in the bag goes into the machine.
               </Typography>
               <Typography variant="body2">
                  Let’s just say that one more time… Everything in the bag goes
                  into the machine - we trust that you have separated multiple
                  loads into separate bags.
               </Typography>
               <Typography variant="subtitle1" className="mt-3">
                  The prepare stage is up to you!
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     We don’t check for these items so any lost items, damage,
                     or other issues that result are not our responsibility.
                  </Typography>
                  <Typography variant="body2" component="li">
                     We deliver our Solutions with reasonable care and skill,
                     and always aim to protect your beloved laundry however all
                     washing and drying processes can cause normal wear and
                     tear.
                  </Typography>
                  <Typography variant="body2" component="li">
                     This means we are not responsible for wear and tear
                     including missing buttons, holes, fading, colour bleeding,
                     stretching, or shrinking.
                  </Typography>
               </ul>

               <Typography variant="subtitle1" className="mt-3">
                  Things we don’t wash
               </Typography>
               <Typography variant="body2">
                  Not to be a detergent diva about it, but there are some things
                  we don’t wash listed here. We might also take a hard pass on
                  anything that looks too high maintenance (looking at you,
                  Nonna’s lacy tablecloth and Ron Weasley’s dress robes!).
               </Typography>

               <Typography variant="h6">
                  2.Lonely socks and other lost laundry items
               </Typography>
               <Typography variant="body2">
                  If you believe you have lost an item, let us know within 24
                  hours orders@foamlaundry.com.au
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     We follow the AU/NZ Laundry Practice AS/NZ 4146:2000
                     guidelines to help ensure safe infection control and
                     disinfection of soiled linen.
                  </Typography>
                  <Typography variant="body2" component="li">
                     All personal clothing is washed on a cold setting with
                     detergent and softener.
                  </Typography>
                  <Typography variant="body2" component="li">
                     If you need us to use a specific detergent with your
                     garments, you are responsible for providing this with your
                     laundry for pick up.
                  </Typography>
               </ul>
               <Typography variant="h6">
                  4. Can you cancel or re-schedule Bookings?
               </Typography>
               <Typography variant="body2">
                  We know sometimes things happen which mean you might need to
                  reschedule a Booking you’ve made with us. A ‘Ghosting Fee’
                  will apply for cancelling outside the timeframes listed below.
                  This is because we need to do the admin run around to update
                  our systems and pick up or drop off route.
               </Typography>
               <Typography variant="subtitle1">To cancel a booking</Typography>
               <Typography variant="body2">
                  To cancel your booking please send an email to
                  orders@foamlaundry.com.au within 24 hours of time of pick
                  up/drop off.
               </Typography>
               <Typography variant="subtitle1">
                  To reschedule a pick up booking
               </Typography>
               <Typography variant="body2">
                  You can cancel or reschedule your order for free anytime up to
                  6 hours before your original Booking.
               </Typography>
               <Typography variant="subtitle1">
                  Reschedule a drop off booking
               </Typography>
               <Typography variant="body2">
                  You can reschedule your drop off anytime up to 2 hours before
                  your original Booking.
               </Typography>
               <Typography variant="h6">5. ‘OK to leave’.</Typography>
               <Typography variant="body2">
                  If you tell us your laundry is ‘OK to Leave’ for pick up and
                  drop off, you are asking us to pick up your laundry
                  unattended, without you there, and leave your freshly washed
                  laundry unattended, without you there.
               </Typography>
               <Typography variant="body2">
                  We are not responsible for unattended laundry after we drop it
                  off or before we pick it up.
               </Typography>
               <Typography variant="subtitle1">Tidy timing</Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     If you opt for pick up or drop off with a person present,
                     we aim to meet the pick up and drop off windows we agree
                     with you (however we might be late, due to unforeseen
                     laundry emergencies or other issues).
                  </Typography>
                  <Typography variant="body2" component="li">
                     If you aren’t available at the booked pick up time and you
                     are not comfortable with your laundry being left
                     unattended, we will be sad and try to coordinate an
                     alternate time as soon as possible.
                  </Typography>
                  <Typography variant="body2" component="li">
                     A Ghosting fee may apply (see below).
                  </Typography>
               </ul>
               <Typography variant="subtitle1">Ghosting fee</Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     We may charge a Ghosting fee if you selected in person pick
                     up or delivery and you ghosted us (i.e. you weren’t at the
                     location you said you would be at the time you said you
                     would be there).
                  </Typography>
                  <Typography variant="body2" component="li">
                     This fee may also apply if you tell us the laundry is ‘Safe
                     to Leave’ but we get bad vibes or blocked from pick up or
                     drop off.
                  </Typography>
                  <Typography variant="body2" component="li">
                     For example, if it is pouring rain and there’s no where dry
                     to leave your clean laundry... if there is a not so
                     friendly dog on the loose in your yard... if your gate is
                     locked so we can’t get to your laundry to pick it up.
                  </Typography>
               </ul>
               <Typography variant="subtitle1">How we charge you</Typography>
               <Typography variant="body2">
                  We weigh your clothes and count your larger items.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     Clothes, sheets, and towels are weighed and charged by the
                     kilo (and we round up ⬆️ to the nearest kilo)
                  </Typography>
                  <Typography variant="body2" component="li">
                     Pillows, doonas, and other specialty items listed on the
                     website are charged per item.
                  </Typography>
                  <Typography variant="body2" component="li">
                     If your actual Load is greater than your booked Load, then
                     we will charge you the top up difference
                  </Typography>
               </ul>
               <Typography variant="body2">
                  Foam customers with a weekly or fortnightly booking get a
                  discount and free delivery.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     To be eligible for these special mates rates, you must book
                     at least four consecutive orders.
                  </Typography>
               </ul>
               <Typography variant="h6">7.Paying for Services</Typography>
               <Typography variant="body2">
                  For most Services, you will need to pay upfront via a debit or
                  credit card.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     Merchant fees may apply to your selected method of payment.
                  </Typography>
                  <Typography variant="body2" component="li">
                     If you haven’t paid our Fees – including any additional
                     Fees discovered after pick up or Service delivery – then we
                     can hold your laundry hostage until you do pay in full.
                  </Typography>
                  <Typography variant="body2" component="li">
                     If you still haven’t paid us within 14 days, then we
                     reserve the right to treat your laundry as Abandoned.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Unless the relevant consumer laws apply and once we have
                     laundered your clothes, we cannot offer a refund.
                  </Typography>
               </ul>

               <Typography variant="h6">
                  8.We don’t air your dirty laundry
               </Typography>
               <Typography variant="body2">
                  Protecting personal information.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     We handle information you give us in line with our Privacy
                     Policy, available on our Website.
                  </Typography>
                  <Typography variant="body2" component="li">
                     When you buy our Solutions, you consent to our collection
                     and use of your information in line with these Terms and
                     our Privacy Policy, including for marketing purposes (which
                     you can opt out of at any time).
                  </Typography>
               </ul>

               <Typography variant="h6">
                  9.We use reasonable care + skill
               </Typography>
               <Typography variant="body2">
                  We use reasonable care and skill to deliver Solutions to you,
                  however you agree that:
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     We cannot and do not promise our Solutions will be
                     continuously available or fault free and
                  </Typography>
                  <Typography variant="body2" component="li">
                     If things outside our reasonable control impact on our
                     ability to supply the Service, you agree we are not
                     responsible for impacts on you as a result.
                  </Typography>
               </ul>

               <Typography variant="h6">
                  10.Paying for losses + limiting liability
               </Typography>
               <Typography variant="body2">
                  If you cause us loss to the extent allowed by law, you must
                  pay us for costs we incur caused or contributed to by:
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     inaccurate or misleading info you give us,
                  </Typography>
                  <Typography variant="body2" component="li">
                     your use or misuse of the Laundromats or our Solutions.
                  </Typography>
               </ul>
               <Typography variant="body2">
                  For example, if you book in a SERVICE solution and claim the
                  laundry is only one load but it ends up being four loads with
                  special needs, we can charge you the extra amounts between
                  what you booked and what the actual Laundry Solutions needed.
               </Typography>
               <Typography variant="subtitle1">
                  Limiting our liability to you
               </Typography>
               <Typography variant="body2">
                  Nothing in these Terms restricts consumer laws that may apply
                  to your purchase of our Solutions.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     To the extent allowed by law, we exclude all liability for
                     claims by you or a third party for all forms of loss or
                     damage, including loss or inaccuracy of data or loss of
                     business profits or revenue.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Unless we cause loss with our negligence or wilful
                     misconduct, we are not responsible for any loss caused by
                     your use of our Solutions (or any inability to use a
                     Service).
                  </Typography>
                  <Typography variant="body2" component="li">
                     Where we cannot exclude our liability, our total liability
                     to you is limited to us re-supplying the relevant Service
                     to you or, if applicable, paying to you the cost of
                     re-supplying the relevant Solutions to you.
                  </Typography>
               </ul>

               <Typography variant="h6">
                  11.Let us know if you have an issue
               </Typography>
               <Typography variant="body2">
                  If a dispute crops up under these Terms, we both agree to act
                  reasonably to resolve it.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     If you are a consumer, you have specific rights when you
                     buy our Solutions (see accc.gov.au), and these Terms do not
                     replace legal rights you have under law.
                  </Typography>
                  <Typography variant="body2" component="li">
                     If you have an issue with our Solutions, you’ll need to
                     work with us so we can help resolve that issue.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Contact us via email and include relevant information so we
                     can help resolve your issue.
                  </Typography>
                  <Typography variant="body2" component="li">
                     We confirm we received your complaint within 5 business
                     days and work to investigate and resolve your complaint
                     within 14 days. If we can’t resolve your issue after this,
                     we will be in touch to set out some proposed next steps.
                  </Typography>
               </ul>

               <Typography variant="h6">
                  12.Managing delays and problems
               </Typography>
               <Typography variant="body2">
                  Things outside our reasonable control
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     Neither you or we are liable to the other for a delay or
                     failure to perform obligations in this Agreement caused by
                     circumstances outside of reasonable control, other than the
                     payment of money.
                  </Typography>
                  <Typography variant="body2" component="li">
                     How to manage delays and problems
                  </Typography>
                  <Typography variant="body2" component="li">
                     If we become aware of anything likely to result in a
                     material delay in or failure to perform any Solutions, we
                     will let you know promptly.
                  </Typography>
               </ul>

               <Typography variant="h6">
                  13.General agreement details
               </Typography>
               <Typography variant="subtitle1">Changing these Terms</Typography>
               <Typography variant="body2">
                  We may change our Terms from time to time. When we do change
                  these Terms, the changes take effect on and from the day we
                  put the updated Terms on our Website or otherwise notify you.
               </Typography>

               <Typography variant="subtitle1">
                  Transferring this Agreement
               </Typography>
               <Typography variant="body2">
                  Some rights and obligations under our agreement with you
                  remain in force after these Terms expire or end. These include
                  the rights and obligations under the following sections:
                  Paying for Services, Paying for losses + limiting liability,
               </Typography>

               <Typography variant="subtitle1">Survival</Typography>
               <Typography variant="body2">
                  We may assign, subcontract, or transfer some or all our rights
                  or obligations under this Agreement without notice to you. You
                  cannot assign, subcontract, or transfer your rights or
                  obligations under this Agreement without our consent.
               </Typography>

               <Typography variant="subtitle1">
                  Other general details
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     <b>Invalid parts severed:</b> If a court of law decides one
                     or more parts of our Agreement with you is invalid, only
                     those parts are severed from the Agreement and other parts
                     remain in full force and effect.
                  </Typography>
                  <Typography variant="body2" component="li">
                     <b>No waiver:</b> Delay or failure to exercise rights or a
                     partial exercise of rights is not a waiver of those rights.
                  </Typography>
                  <Typography variant="body2" component="li">
                     <b>Force Majeure:</b> Neither party is liable for
                     performance delays or for non-performance due to causes
                     beyond its reasonable control, except for payment
                     obligations.
                  </Typography>
                  <Typography variant="body2" component="li">
                     <b>Full agreement:</b> This agreement outlines the full
                     agreement between us for the Solutions we supply you.
                  </Typography>
                  <Typography variant="body2" component="li">
                     <b>Governing law:</b> This Agreement is governed by the
                     laws in force in New South Wales, and the parties agree to
                     submit to that jurisdiction.
                  </Typography>
               </ul>

               <Typography variant="h6">Dictionary</Typography>
               <Typography variant="body1">
                  Capitalised words have the following meanings.
               </Typography>
               <ul>
                  <Typography variant="body2" component="li">
                     Confidential Information includes all material, IP,
                     non-public, business-related information disclosed or made
                     available to the receiving party through any means,
                     relating to a party’s business but excludes information
                     that, without breaching this agreement, is already public
                     or known to the receiving party.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Fees means the charges you pay to us for the Solutions we
                     provide, as set out on our Website or in a Laundromat or as
                     otherwise agreed with you.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Solutions means the various laundry services that we
                     provide (including self service and done for you services)
                     and other related products and services.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Service Laundry means our range of ‘done for you’ Laundry
                     Solutions, set out on our Website, where we do the laundry
                     for you.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Bookings means scheduled times we agree with you to pick up
                     or drop off your laundry for Service Laundry solutions.
                  </Typography>
                  <Typography variant="body2" component="li">
                     Website includes our website foamlaundry.com.au, and our
                     presence on third-party applications such as Instagram and
                     Facebook.
                  </Typography>
               </ul>
               <Typography variant="body2">
                  If you have any questions or notices about these Terms or our
                  other policies, please contact us, we are happy to help!
               </Typography>
               <Typography variant="body2">
                  <b>Last updated: November 2022</b>
               </Typography>
            </div>
         </CustomDailog>
      </Container>
   );
}
