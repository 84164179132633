import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  Divider
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { getCustomerInformation } from "../../../cleancloud/getCustomerInformation";
import { updateCustomerInformation } from "../../../cleancloud/updateCustomerInformation";
import CustomButton from "../../shared/Button";
import CustomizedSnackbars from "../../shared/Snackbar";
import TextFeildCustom from "../../shared/TextFeild";
import CustomDailog from "../../shared/Dailog";
import { auth } from "../../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

export default function MyAccount() {
  const [user] = useAuthState(auth);
  const [fnerr, setfnerr] = useState({
    err: false,
    message: "",
  });
  const [lnerr, setlnerr] = useState({
    err: false,
    message: "",
  });
  const [emerr, setemerr] = useState({
    err: false,
    message: "",
  });
  const [pnerr, setpnerr] = useState({
    err: false,
    message: "",
  });
  const [perr, setperr] = useState({
    err: false,
    message: "",
  });
  const [cperr, setcperr] = useState({
    err: false,
    message: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    open: false,
    message: "Error !",
    severity: "error",
  });
  const [privacyPolicyDailog, setPrivacyPolicyDailog] = useState(false);
  const navigate = useNavigate();

  const handleErrorClose = () => {
    setError({ ...error, open: false });
  };

  async function getCustomer() {
    const response = await getCustomerInformation();
    setFirstName(response.Name?.split(" ")[0]);
    setLastName(response.Name?.split(" ")[1]);
    setEmail(response.Email);
    setPhone(response.Tel);

    setAcceptTerms(response.marketingOptIn === "0" ? false : true);
  }

  async function updateCustomer() {
    if (firstName.trim() === "") {
      setfnerr({
        err: true,
        message: "First name is required",
      });
      return;
    }
    if (lastName.trim() === "") {
      setlnerr({
        err: true,
        message: "Last name is required",
      });
      return;
    }
    if (email.trim() === "") {
      setemerr({
        err: true,
        message: "Email is required",
      });
      return;
    }
    if (phone.trim() === "") {
      setpnerr({
        err: true,
        message: "Phone no is required",
      });
      return;
    }

    if (password) {
      if (password === confirmPassword) {
        setLoader(true);
        try {
          const res = await updateCustomerInformation({
            customerName: firstName + " " + lastName,
            customerTel: phone,
            customerEmail: email,
            customerPassword: password,
            marketingOptIn: acceptTerms,
          });
          if (res.Success) {
            setLoader(false);
            setError({
              ...error,
              open: true,
              severity: "success",
              message: "Information Updated! ",
            });
          } else {
            setError({
              open: true,
              severity: "error",
              message: res.Error,
            });
          }
        } catch (e) {
          setError({
            severity: "error",
            open: true,
            message: "Something went wrong! Try again",
          });
        }
      } else {
        setcperr({
          err: true,
          message: "Passwords do not match",
        });
        return;
      }
    } else {
      setLoader(true);
      try {
        const res = await updateCustomerInformation({
          customerName: firstName + " " + lastName,
          customerTel: phone,
          customerEmail: email,
          marketingOptIn: acceptTerms,
        });

        if (res.Success) {
          setError({
            open: true,
            severity: "success",
            message: "Information Updated ",
          });
        } else {
          setError({
            ...error,
            open: true,
            severity: "error",
            message: "Update failed ! Try again ",
          });
        }
      } catch (e) {
        setError({
          ...error,
          open: true,
          message: "Something went wrong! Try again",
        });
      }
    }
    setLoader(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("cid")) {
      navigate("/login");
    }
    if (!user) {
      localStorage.removeItem("cid");
      navigate("/login");
    }
    getCustomer();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ScrollRestoration />
      <CustomizedSnackbars
        severity={error.severity}
        message={error.message}
        open={error.open}
        handleClose={handleErrorClose}
      />

      <CustomDailog
        open={privacyPolicyDailog}
        handleClickClose={() => {
          setPrivacyPolicyDailog(false);
        }}
      >
        <div className="mt-3 mx-4 fm-disclaimers">
          <IconButton
            color="secondary"
            size="small"
            className="d-flex align-items-center bg-white"
            onClick={() => setPrivacyPolicyDailog(false)}
            style={{ position: "absolute", right: 16 }}
          >
            <CloseOutlined />
          </IconButton>
          <Typography variant="h1" className="py-3">
            Privacy Policy
          </Typography>
          <Typography variant="body2">
            The Trustee Sapouni Pty Ltd as trustee for the Sapouni Pty Ltd
            trading as "Foam Laundry Lounge" (ABN 79 302 658 369) ("we", "us" or
            the "Company") is committed to privacy protection. At
            https://foamlaundry.com.au/ ("this site"), we understand the
            importance of keeping personal information private and secure. This
            privacy policy ("Privacy Policy") describes generally how we manage
            personal information and safeguard privacy. If you would like more
            information, please don't hesitate to contact us.
          </Typography>

          <Typography variant="h6">We care about your privacy:</Typography>
          <Typography variant="subtitle2">
            We will never rent, trade or sell your email address to anyone.
            <br />
            We will never publicly display your email address or other personal
            details that identify you.
          </Typography>

          <Typography variant="h6">
            The Australian Privacy Principles
          </Typography>
          <Typography variant="body2">
            We will treat all personal information in accordance with any and
            all obligations that are binding upon us under the Privacy Act 1988
            (Cth) (“Privacy Act”). The Privacy Act lays down 13 key principles
            in relation to the collection and treatment of personal information,
            which are called the “Australian Privacy Principles”.
          </Typography>

          <Typography variant="h6">What is "personal information"?</Typography>
          <Typography variant="body2">
            Personal information held by the Company may include your:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              name and date of birth;
            </Typography>
            <Typography variant="body2" component="li">
              residential and business postal addresses, telephone/mobile/fax
              numbers and email addresses;
            </Typography>
            <Typography variant="body2" component="li">
              bank account and/or credit card details for agreed billing
              purposes;
            </Typography>
            <Typography variant="body2" component="li">
              any information that you provided to us by you during your account
              creation process or added to your user profile;
            </Typography>
            <Typography variant="body2" component="li">
              preferences and password for using this site and your computer and
              connection information; and
            </Typography>
            <Typography variant="body2" component="li">
              any information that you otherwise share with us.
            </Typography>
          </ul>

          <Typography variant="h6">Information provided to Stripe</Typography>
          <Typography variant="body2">
            All purchases that are made through this site are processed securely
            and externally by Stripe. Unless you expressly consent otherwise, we
            do not see or have access to any personal information that you may
            provide to Stripe, other than information that is required in order
            to process your order and deliver your purchased items to you (eg,
            your name, email address and billing address).
          </Typography>

          <Typography variant="h6">
            How we may collect your personal information
          </Typography>
          <Typography variant="body2">
            At this site, we only collect personal information that is necessary
            for us to conduct our business as provides laundry services, with
            self-service laundromats and a wash, dry & fold laundry delivery
            service, which includes (without limitation) enabling users to:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              find information relating to Foam's Self- Service Laundromats; and
            </Typography>
            <Typography variant="body2" component="li">
              book a wash, dry & fold laundry delivery service.
            </Typography>
          </ul>

          <Typography variant="h6">
            Information that you provide to us
          </Typography>
          <Typography variant="body2">
            We may collect personal information that you provide to us about
            yourself when you:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              use this site, including (without limitation) when you:
              <ul>
                <Typography variant="body2" component="li">
                  create a user account;
                </Typography>
                <Typography variant="body2" component="li">
                  add information to your user profile;
                </Typography>
                <Typography variant="body2" component="li">
                  purchase any services through this site;
                </Typography>
                <Typography variant="body2" component="li">
                  register for access to premium content or request certain
                  premium features; or
                </Typography>
                <Typography variant="body2" component="li">
                  complete an online contact form to contact us;
                </Typography>
              </ul>
            </Typography>
            <Typography variant="body2" component="li">
              provide information to us by telephone or through marketing or
              competition application forms; or
            </Typography>
            <Typography variant="body2" component="li">
              send us an email or other communication.
            </Typography>
          </ul>

          <Typography variant="h6">IP addresses</Typography>
          <Typography variant="body2">
            This site may also collect Internet Protocol (IP) addresses. IP
            addresses are assigned to computers on the internet to uniquely
            identify them within the global network. The Company collects and
            manages IP addresses as part of the service of providing internet
            session management and for security purposes. The Company may also
            collect and use web log, computer and connection information for
            security purposes and to help prevent and detect any misuse of, or
            fraudulent activities involving, this site.
          </Typography>

          <Typography variant="h6">Cookies</Typography>
          <Typography variant="body2">
            This site uses "cookies" to help personalise your online experience.
            A cookie is a text file or a packet of information that is placed on
            your hard disk by a web page server to identify and interact more
            effectively with your computer. There are two types of cookies that
            may be used at this site: a persistent cookie and a session cookie.
            A persistent cookie is entered by your web browser into the
            "Cookies" folder on your computer and remains in that folder after
            you close your browser, and may be used by your browser on
            subsequent visits to this site. A session cookie is held temporarily
            in your computer’s memory and disappears after you close your
            browser or shut down your computer. Cookies cannot be used to run
            programs. Cookies are uniquely assigned to you, and can only be read
            by a web server in the domain that issued the cookie to you. In some
            cases, cookies may collect and store personal information about you.
            The Company extends the same privacy protection to your personal
            information, whether gathered via cookies or from other sources.
          </Typography>
          <Typography variant="body2">
            You can configure your internet browser to accept all cookies,
            reject all cookies or notify you when a cookie is sent. Please refer
            to your internet browser’s instructions to learn more about these
            functions. Most web browsers automatically accept cookies, but you
            can usually modify your browser settings to decline cookies if you
            prefer. If you choose to decline cookies, you may not be able to
            fully experience the interactive features of this site.
          </Typography>

          <Typography variant="h6">Why we use cookies</Typography>
          <Typography variant="body2">
            This site uses cookies in order to:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              remember your preferences for using this site;
            </Typography>
            <Typography variant="body2" component="li">
              manage the signup process when you create an account with us;
            </Typography>
            <Typography variant="body2" component="li">
              recognise you as logged in while you remain so. This avoids your
              having to log in again every time you visit a new page;
            </Typography>
            <Typography variant="body2" component="li">
              facilitate e-commerce transactions, to ensure that your order is
              remembered between pages during the checkout process;
            </Typography>
            <Typography variant="body2" component="li">
              show relevant notifications to you (eg, notifications that are
              relevant only to users who have, or have not, created an account
              or subscribed to newsletters or email or other subscription
              services); and
            </Typography>
            <Typography variant="body2" component="li">
              remember details of data that you choose to submit to us (eg,
              through online contact forms).
            </Typography>
          </ul>
          <Typography variant="body2">
            Many of these cookies are removed or cleared when you log out but
            some may remain so that your preferences are remembered for future
            sessions.
          </Typography>

          <Typography variant="h6">Third party cookies</Typography>
          <Typography variant="body2">
            In some cases, third parties may place cookies through this site.
            For example:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              Google Analytics, one of the most widespread and trusted website
              analytics solutions, may use cookies de-identified data about how
              long users spend on this site and the pages that they visit;
            </Typography>
            <Typography variant="body2" component="li">
              Google AdSense, one of the most widespread and trusted website
              advertising solutions, may use cookies to serve more relevant
              advertisements across the web and limit the number of times that a
              particular advertisement is shown to you; and
            </Typography>
            <Typography variant="body2" component="li">
              third party social media applications (eg, Facebook, Twitter,
              LinkedIn, Pinterest, YouTube, Instagram, etc) may use cookies in
              order to facilitate various social media buttons and/or plugins in
              this site.
            </Typography>
          </ul>

          <Typography variant="h6">
            How we may use your personal information
          </Typography>
          <Typography variant="body2">
            Your personal information may be used in order to:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              verify your identity;
            </Typography>
            <Typography variant="body2" component="li">
              assist you to place orders through this site;
            </Typography>
            <Typography variant="body2" component="li">
              process any purchases of services that you may make through this
              site, including charging, billing and collecting debts;
            </Typography>
            <Typography variant="body2" component="li">
              make changes to your account;
            </Typography>
            <Typography variant="body2" component="li">
              respond to any queries or feedback that you may have;
            </Typography>
            <Typography variant="body2" component="li">
              conduct appropriate checks for credit-worthiness and for fraud;
            </Typography>
            <Typography variant="body2" component="li">
              prevent and detect any misuse of, or fraudulent activities
              involving, this site;
            </Typography>
            <Typography variant="body2" component="li">
              conduct research and development in respect of our services;
            </Typography>
            <Typography variant="body2" component="li">
              gain an understanding of your information and communication needs
              or obtain your feedback or views about our services in order for
              us to improve them; and/or
            </Typography>
            <Typography variant="body2" component="li">
              maintain and develop our business systems and infrastructure,
              including testing and upgrading of these systems,
            </Typography>
          </ul>
          <Typography variant="body2">
            and for any other purpose reasonably considered necessary or
            desirable by the Company in relation to the operation of our
            business.
          </Typography>
          <Typography variant="body2">
            From time to time we may email our customers with news, information
            and offers relating to our own services or those of selected
            partners. Your personal information may also be collected so that
            the Company can promote and market products and services to you.
            This is to keep you informed of products, services, and special
            offers we believe you will find valuable and may continue after you
            cease acquiring products and services from us. If you would prefer
            not to receive promotional or other material from us, please let us
            know and we will respect your request. You can unsubscribe from such
            communications at any time if you choose.
          </Typography>

          <Typography variant="h6">
            When we may disclose your personal information
          </Typography>
          <Typography variant="body2">
            In order to deliver the services you require or for the purposes set
            out above, the Company may disclose your personal information to
            organisations outside the Company. Your personal information may be
            disclosed to these organisations only in relation to this site, and
            the Company takes reasonable steps to ensure that these
            organisations are bound by confidentiality and privacy obligations
            in relation to the protection of your personal information. These
            organisations may carry out or provide:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              customer enquiries;
            </Typography>
            <Typography variant="body2" component="li">
              mailing systems;
            </Typography>
            <Typography variant="body2" component="li">
              billing and debt-recovery functions;
            </Typography>
            <Typography variant="body2" component="li">
              information technology services;
            </Typography>
            <Typography variant="body2" component="li">
              marketing, telemarketing and sales services;
            </Typography>
            <Typography variant="body2" component="li">
              market research; and
            </Typography>
            <Typography variant="body2" component="li">
              website usage analysis.
            </Typography>
          </ul>
          <Typography variant="body2">
            In addition, we may disclose your personal information to:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              your authorised representatives or legal advisers (when requested
              by you to do so);
            </Typography>
            <Typography variant="body2" component="li">
              credit-reporting and fraud-checking agencies;
            </Typography>
            <Typography variant="body2" component="li">
              credit providers (for credit-related purposes such as
              creditworthiness, credit rating, credit provision and financing);
            </Typography>
            <Typography variant="body2" component="li">
              our professional advisers, including our accountants, auditors and
              lawyers;
            </Typography>
            <Typography variant="body2" component="li">
              government and regulatory authorities and other organisations, as
              required or authorised by law;
            </Typography>
            <Typography variant="body2" component="li">
              organisations who manage our business strategies, including those
              involved in a transfer/sale of all or part of our assets or
              business (including accounts and trade receivables) and those
              involved in managing our business risk and funding functions; and
            </Typography>
            <Typography variant="body2" component="li">
              the police or other appropriate persons where your communication
              suggests possible illegal activity or harm to others.
            </Typography>
          </ul>

          <Typography variant="h6">Contacting us about privacy</Typography>
          <Typography variant="body2">
            If you would like more information about the way we manage personal
            information that we hold about you, or are concerned that we may
            have breached your privacy, please contact us by email or by post.
          </Typography>
          <Typography variant="h6">
            Access to your personal information
          </Typography>
          <Typography variant="body2">
            In most cases, you may have access to personal information that we
            hold about you. We will handle requests for access to your personal
            information in accordance with the Australian Privacy Principles.
            All requests for access to your personal information must be
            directed to the Privacy Officer by email or by writing to us at our
            postal address. We will deal with all requests for access to
            personal information as quickly as possible. Requests for a large
            amount of information, or information that is not currently in use,
            may require further time before a response can be given. We may
            charge you a fee for access if a cost is incurred by us in order to
            retrieve your information, but in no case will we charge you a fee
            for your application for access.
          </Typography>
          <Typography variant="body2">
            In some cases, we may refuse to give you access to personal
            information that we hold about you. This may include circumstances
            where giving you access would:
          </Typography>
          <ul>
            <Typography variant="body2" component="li">
              be unlawful (eg, where a record that contains personal information
              about you is subject to a claim for legal professional privilege
              by one of our contractual counterparties);
            </Typography>
            <Typography variant="body2" component="li">
              have an unreasonable impact on another person’s privacy; or
            </Typography>
            <Typography variant="body2" component="li">
              prejudice an investigation of unlawful activity.
            </Typography>
          </ul>
          <Typography variant="body2">
            We may also refuse access where the personal information relates to
            existing or anticipated legal proceedings, and the information would
            not be accessible by the process of discovery in those proceedings.
          </Typography>
          <Typography variant="body2">
            If we refuse to give you access, we will provide you with reasons
            for our refusal.
          </Typography>

          <Typography variant="h6">
            Correcting your personal information
          </Typography>
          <Typography variant="body2">
            We will amend any personal information about you that is held by us
            and that is inaccurate, incomplete or out of date if you request us
            to do so. If we disagree with your view about the accuracy,
            completeness or currency of a record of your personal information
            that is held by us, and you ask us to associate with that record a
            statement that you have a contrary view, we will take reasonable
            steps to do so.
          </Typography>

          <Typography variant="h6">
            Storage and security of your personal information
          </Typography>
          <Typography variant="body2">
            We are committed to maintaining the confidentiality of the
            information that you provide us and we will take all reasonable
            precautions to protect your personal information from unauthorised
            use or alteration. In our business, personal information may be
            stored both electronically (on our computer systems and with our
            website hosting provider) and in hard-copy form. Firewalls,
            anti-virus software and email filters, as well as passwords, protect
            all of our electronic information. Likewise, we take all reasonable
            measures to ensure the security of hard-copy information.
          </Typography>

          <Typography variant="h6">Third party websites</Typography>
          <Typography variant="body2">
            You may click-through to third party websites from this site, in
            which case we recommend that you refer to the privacy statement of
            the websites you visit. This Privacy Policy applies to this site
            only and the Company assumes no responsibility for the content of
            any third party websites.
          </Typography>

          <Typography variant="h6">Re-marketing</Typography>
          <Typography variant="body2">
            We may use the Google AdWords and/or Facebook re-marketing services
            to advertise on third party websites to previous visitors to this
            site based upon their activity on this site. This allows us to
            tailor our marketing to better suit your needs and to only display
            advertisements that are relevant to you. Such advertising may be
            displayed on a Google search results page or a website in the Google
            Display Network or inside Facebook. Google and Facebook may use
            cookies and/or pixel tags to achieve this. Any data so collected by
            Google and/or Facebook will be used in accordance with their own
            respective privacy policies. None of your personal Google and/or
            Facebook information is reported to us.
          </Typography>
          <Typography variant="body2">
            You can set preferences for how Google advertises to you using the
            Google Ads Settings page (https://www.google.com/settings/ads).
            Facebook has enabled an AdChoices link that enables you to opt out
            of targeted advertising.
          </Typography>

          <Typography variant="h6">Changes to this Privacy Policy</Typography>
          <Typography variant="body2">
            From time to time, it may be necessary for us to revise this Privacy
            Policy. Any changes will be in accordance with any applicable
            requirements under the Privacy Act and the Australian Privacy
            Principles. We may notify you about changes to this Privacy Policy
            by posting an updated version on this site.
          </Typography>
          <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
          <Typography variant="body2">
            If you require any further information about the Privacy Act and the
            Australian Privacy Principles, you can visit the Federal Privacy
            Commissioner’s website (see www.privacy.gov.au).
          </Typography>
        </div>
      </CustomDailog>
      <Grid container justifyContent={"center"} spacing={4} className="mb-5">
        <Grid item xs={12} className="mb-1 ">
          <Typography variant="h4">My Account</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={firstName}
            error={fnerr.err}
            helperText={fnerr.message}
            onChange={(e) => {
              setFirstName(e.target.value);
              setfnerr({
                err: false,
                message: "",
              });
            }}
            label="First Name*"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={lastName}
            error={lnerr.err}
            helperText={lnerr.message}
            onChange={(e) => {
              setLastName(e.target.value);
              setlnerr({
                err: false,
                message: "",
              });
            }}
            label="Last Name*"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={email}
            error={emerr.err}
            helperText={emerr.message}
            onChange={(e) => {
              setEmail(e.target.value);
              setemerr({
                err: false,
                message: "",
              });
            }}
            label="Email*"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={phone}
            error={pnerr.err}
            helperText={pnerr.message}
            onChange={(e) => {
              setPhone(e.target.value);
              setpnerr({
                err: false,
                message: "",
              });
            }}
            label="Phone*"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFeildCustom
            type="password"
            variant="filled"
            value={password}
            error={perr.err}
            helperText={perr.message}
            onChange={(e) => {
              setPassword(e.target.value);
              setperr({
                err: false,
                message: "",
              });
            }}
            label="Change Password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            type="password"
            variant="filled"
            value={confirmPassword}
            error={cperr.err}
            helperText={cperr.message}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setcperr({
                err: false,
                message: "",
              });
            }}
            label="Repeat Password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex align-items-center">
            <FormControlLabel
              className="d-flex align-items-start"
              control={
                <Checkbox
                  className="m-0 p-0 px-2"
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  I agree to have my order messages communicated by SMS and
                  receive service and marketing messages sent from FOAM Laundry,
                  knowing I can opt out anytime using my 'My Account'
                  preferences or simply texting 'STOP'. Phone numbers will be
                  kept confidential, consistent with our{" "}
                  <a
                    href="https://foamlaundry.com.au/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-dark"
                  >
                    Privacy Policy
                  </a>
                  .
                </Typography>
              }
            ></FormControlLabel>
          </div>
        </Grid>
        <Grid item>
          <CustomButton
            variant="contained"
            onClick={updateCustomer}
            loader={loader ? 1 : 0}
            disabled={loader}
          >
            Save Your Profile
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
}
