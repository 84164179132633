import { Container } from "@mui/system";
import { LogoIcon } from "../../assets/Icons";
// import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Box, Typography, List } from "@mui/material";
// import { Drawer } from "../navbar/Drawer";
// import { useState } from "react";
import ProfileMenu from "../navbar/ProfileMenu";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import CustomButton from "../shared/Button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NavbarUser() {
  // const [drawer, setDrawer] = useState(false);
  const { showScheduleButton } = useSelector((store) => store.scheduleButton);

  // const toggleDrawer = () => {
  //   setDrawer(!drawer);
  // };

  return (
    <div
      className="navbar-container d-flex align-items-center"
      style={{
        backgroundColor: "rgba(255,255,255,0.6)",
        backdropFilter: "blur(4px)",
        height: 75,
        position: "sticky",
        zIndex: 10,
        top: 0,
      }}
    >
      {/* <Drawer
        anchor="left"
        open={drawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      /> */}
      <Container maxWidth="xl">
        <div className="d-flex align-items-center justify-content-between navbar py-3">
          {/* <MenuOutlinedIcon
            sx={{ display: { md: "none" } }}
            onClick={toggleDrawer}
          /> */}
          <Box onClick={() => window.location.replace("https://foamlaundry.com.au/")}>
            <LogoIcon />
          </Box>
          <div className="d-flex align-items-center">
            <List className="d-flex align-items-center mx-3">
              <div className="d-flex align-items-center mx-3" onClick={() => window.location.href='tel:0483960570'} style={{cursor:'pointer'}}>
                <PhoneIcon  />
                <span className="mx-1"></span>
                <Typography className="d-none d-md-flex">0483 960 570</Typography>
              </div>
              {showScheduleButton ? (
                <Link to={"/order"} className="text-decoration-none">
                  <CustomButton size="small" variant={"contained"} sx={{fontSize:{xs: "14px", md: "16px"}}}>
                    Schedule<div className="d-none d-md-flex" style={{color:'white'}}>&nbsp;pick-up</div>
                  </CustomButton>
                </Link>
              ) : (
                ""
              )}
            </List>
            <ProfileMenu />
          </div>
        </div>
      </Container>
    </div>
  );
}
